import i18n from '@/translation/i18n';
import { storeToRefs } from 'pinia';
import { int } from '@kitware/vtk.js/types';
import { useDICOMStore } from '../store/datasets-dicom';
import { useModuleStore } from '../store/modules';
import { getDemoFiles, isDemo } from '../auth/demo';
import { openDicomFiles } from '../io/upload';
import { useTreatmentStore } from '../store/treatments';
import { addOrUpdateAppTourCookie } from './cookie';

function unloadScene() {
  const dicomStore = useDICOMStore();
  const treatmentStore = useTreatmentStore();

  if (Object.keys(dicomStore.patientInfo).length) {
    const patients = Object.entries(dicomStore.patientInfo).map(([key, info]) => ({
      key,
      info,
    }));
    dicomStore.deletePatient(patients[0].key);
    treatmentStore.resetTreatments();
  }
}

function closeTour() {
  const moduleStore = useModuleStore();
  const { selectedModuleIndex } = storeToRefs(moduleStore);

  // Unload data
  unloadScene();
  addOrUpdateAppTourCookie();
  selectedModuleIndex.value = 0
}

const demoDataTreatments = {
  "26": {
    "rod": {
      "origin": [23.5902079082176, 21.762542543670218, 26.110598521507903],
      "planes": {
        "4": {
          "normal": [
            0.9608121267243147, -0.2081681866217234, 0.18304661487753388
          ],
          "viewUp": [
            -0.17988185693514055, 0.034199628174440326, 0.9830935372477514
          ]
        },
        "5": {
          "normal": [
            -0.2530190309681891, -0.9283329160069821, 0.2723588937888609
          ],
          "viewUp": [
            -2.3418766925686896e-17, 0.2815191608767306, 0.9595556065488146
          ]
        },
        "6": {
          "normal": [
            0.11323174072414235, -0.30800000509187786, -0.9446240361942869
          ],
          "viewUp": [
            -0.0016823677673537716, -0.9507966840353892, 0.3098109669879421
          ]
        }
      },
      "size": "large"
    },
    "implant": {
      "model": {
        "manufacturer": "ETK",
        "model": "IBONE G RP",
        "reference": "ITLR4355120-i2",
        "path": "implants/files/ETK/iBone/IBONE G/ITLR4355120-i2.vtp",
        "diameter": 5.5,
        "length": 12,
        "cuff": null
      },
      "origin": [23.57970101984108, 21.866916983696626, 26.456597083331427],
      "planes": {
        "4": {
          "normal": [
            0.9670247429704395, -0.234220013033999, 0.10002065775283708
          ],
          "viewUp": [
            -0.099114933569833, 0.01566572918071088, 0.9949526696645847
          ]
        },
        "5": {
          "normal": [
            -0.2530190309681891, -0.9283329160069821, 0.2723588937888609
          ],
          "viewUp": [
            -2.3418766925686896e-17, 0.2815191608767306, 0.9595556065488146
          ]
        },
        "6": {
          "normal": [
            0.029060565219475087, -0.28868491916330996, -0.9569830202238697
          ],
          "viewUp": [
            -0.0001104576249820674, -0.9573882919091747, 0.28880381977104486
          ]
        }
      },
      "inputSource": {
        "views2D": { "4": null, "5": null, "6": null },
        "transformFilter": null
      }
    },
    "correction": {
      "corrector": {
        "translations": { "Coronal": 0, "Sagittal": 0 },
        "rotations": { "Coronal": 5, "Sagittal": 0 },
        "type": "Classic"
      },
      "indexOrientation": "palatal",
      "drilling": 0.36155141158365034,
      "reference": "5°"
    },
    "report": { "bone": null, "gum": null, "other": null }
  }
}

export function getAccuratorAppTourSteps() {
  return [
    {
      target: '#apptour-step-0',
      content: i18n.t('appTour.step0'),
      params: {
        placement: 'bottom'
      },
    },
    {
      target: '#dicomFileInput',
      content: i18n.t('appTour.step1'),
      params: {
        placement: 'bottom'
      },
    },
    {
      target: '#appTour-step2',
      content: i18n.t('appTour.step2'),
      params: {
        placement: 'bottom'
      },
      before: () => new Promise((resolve) => {
        const moduleStore = useModuleStore();
        const { selectedModuleIndex } = storeToRefs(moduleStore);

        selectedModuleIndex.value = 0;
        if (isDemo()) {
          // In case we are using the demo app, data is already loaded
          resolve("foo");
        } else {
          // // Unload data and load demo data
          unloadScene();
          
          // Load demo data
          getDemoFiles().then(openDicomFiles).then(() => {
            resolve("foo");
          });
        }
      }),
    },
    {
      target: '#apptour-step-3',
      content: i18n.t('appTour.step3'),
      params: {
        placement: 'bottom'
      },
    },
    {
      target: '#apptour-step-4',
      content: i18n.t('appTour.step4'),
      params: {
        placement: 'top'
      },
      before: () => new Promise((resolve) => {
        const treatmentStore = useTreatmentStore();
        const { selectedTooth } = storeToRefs(treatmentStore);
        treatmentStore.setTreatments(demoDataTreatments);
        selectedTooth.value = 26;
        setTimeout(() => {
          resolve("foo"); // Needs to return a promise
        },(25));
      }),
    },
    {
      target: '#apptour-step-5',
      content: i18n.t('appTour.step5'),
      params: {
        placement: 'bottom'
      },
    },
    {
      target: '#apptour-step-6',
      content: i18n.t('appTour.step6'),
      params: {
        placement: 'left'
      }
    },
    {
      target: '#apptour-step-7',
      content: i18n.t('appTour.step7'),
      params: {
        placement: 'top'
      }
    },
    {
      target: '#apptour-step-8',
      content: i18n.t('appTour.step8'),
      params: {
        placement: 'bottom'
      }
    },
    {
      target: '#apptour-step-9',
      content: i18n.t('appTour.step9'),
      params: {
        placement: 'top'
      }
    },
    {
      target: '#apptour-step-10',
      content: i18n.t('appTour.step10'),
      params: {
        placement: 'left'
      }
    },
    {
      target: '#apptour-step-11',
      content: i18n.t('appTour.step11'),
      params: {
        placement: 'bottom'
      }
    },
    {
      target: '#apptour-step-12',
      content: i18n.t('appTour.step12'),
      params: {
        placement: 'top'
      }
    },
    {
      target: '#apptour-step-13',
      content: i18n.t('appTour.step13'),
      params: {
        placement: 'top'
      }
    },
  ];
}

export function getAccuratorAppTourOptions() {
  return {
    useKeyboardNavigation: true,
    labels: {
      buttonSkip: i18n.t('appTour.skip'),
      buttonPrevious: i18n.t('appTour.previous'),
      buttonNext: i18n.t('appTour.next'),
      buttonStop: i18n.t('appTour.stop')
    }
  };
}

export function getAccuratorAppTourCallbacks() {
  const moduleStore = useModuleStore();
  const { selectedModuleIndex } = storeToRefs(moduleStore);

  function setActiveStep(step: int) {
    selectedModuleIndex.value = step;
    setTimeout(() => {},(25));
  }

  return {
    onNextStep: (currentStep: number) => {
      if (currentStep === 2) {
        console.log('Loading step 2-Marks');
        setActiveStep(1);
      }
      if (currentStep === 7) {
        console.log('Loading step 3-Implants');
        setActiveStep(2);
      }
      if (currentStep === 11) {
        console.log('Loading step 4-Report');
        setActiveStep(3);
      }
    },
    onPreviousStep: (currentStep: number) => {
      if (currentStep === 3) {
        console.log('Reloading step 1-Import');
        setActiveStep(0);
      }
      if (currentStep === 8) {
        console.log('Reloading step 2-Marks');
        setActiveStep(1);
      }
      if (currentStep === 12) {
        console.log('Reloading step 3-Marks');
        setActiveStep(2);
      }
    },
    onSkip: () => {
      console.log('onSkip');
      closeTour();
    },
    onFinish: () => {
      console.log('onFinish');
      closeTour();
    },
    onStop: () => {
      console.log('onStop');
      closeTour();
    },
  };
}
